<template>
	<div id="homepage" :class="[model=='ipad'?'ipad_class':model == 'phone'?'phone_class':'pc_class']" >
		<div class='heard'>
			<div id='wrap_hand' class='wrap_hand wrap_hand_animation'>
				<div class='heard_left myun_down' @click="backClick()" style="cursor: pointer;"><img @dragstart.prevent src="@/image/onhand/logo1.png" alt=""></div>
				<div class='heard_left mydown'><img @dragstart.prevent src="@/image/onhand/logo_pc.png" alt=""></div>
				<div class='heard_right mydown'><img @dragstart.prevent src="@/image/onhand/icon.png" alt=""></div>
				<div class='heard_right myun_down' style="display: flex;align-items: center;">
					<section class="mod model-1">
						<div class="menu" @click="menuPage()" :class="menuFlag ?'menu menu_hover':'menu'">
							<div class="bar"></div>
							<div class="bar"></div>
							<div class="bar"> </div>
						</div>
					</section>
					<!-- <img @dragstart.prevent src="@/image/onhand/icon_model.png" alt="" width="28px" @click="menuPage()"> -->
				</div>
				<!-- @click="menuPage()" -->
				
			</div>

			<div class="handtitle" v-show='display'>
				<!-- <img :src="listdata.logourl" alt="" id='mytitle_img'  class='mytitle_img'> -->
				<!-- <div class='mynone'> -->
				<!-- <img @dragstart.prevent :src="listdata.firstpage_banner_pic" alt="" id='mytitle_img'  class='mytitle_img '> -->
				<img  @dragstart.prevent v-for="(item,index) in  listdata.firstpage_banner_pic" :src="item.picUrl" alt="" id='mytitle_img' style="display: none;"  class='mytitle_img ' :class="{aaa : timeInterval == index }" v-if="onlinestate == 'pc'" >
				<img  @dragstart.prevent v-for="(item,index) in  listdata.firstpage_banner_pic_app" :src="item.picUrl" alt="" id='mytitle_img' style="display: none;"  class='mytitle_img ' :class="{aaa : timeInterval == index }" v-if="(onlinestate == 'ipad' || onlinestate == 'phone')">
				<!-- </div> -->
				<!-- 	<div class='myun_none'>
					<img :src="listdata.firstpage_banner_pic_app" alt="" id='mytitle_img'  class='mytitle_img '>
				</div> -->
				<!-- <div>{{ windowWidth }}</div> -->
				<div class='warp_lit' v-show="false">
					<div >
						<div class='title_font'>
							{{listdata.firstpage_banner_title1_1}}<br class='mynone'>{{listdata.firstpage_banner_title1_2}}</div>
					</div>
					<div v-text='listdata.firstpage_banner_title2' class='lit_tap'></div>
					<div>
						<button class='warp_btn' @click='handtogo'>立即体验</button>
					</div>
					<div class='mydown' style="justify-content: center;">
						<!-- <img :src="listdata.firstpage_banner_pic" alt="" @click='handtogo'> -->
						<img @dragstart.prevent :src="require('@/image/onhand/mydown.png')" alt="" @click='handtogo'>
					</div>
				</div>
			</div>
			
			<a-row>
				<!-- 单行文字 -->
				<a-col :xl='{span:12}' :lg='{span:24}'>
					<a-row class='my_top'>
						<a-col :span="24" class='warp_img'>
							<a-col :span="24" >
								<div class='line_font' id='hediv'>
									<div>
										<div class='warp_headtext warp_headtext1'>
											{{listdata.firstpage_area1_title}}
										</div>
										<div class='warp_meta' :class='text4_line?"text4":""'>
											{{listdata.firstpage_area1_text}}
										</div>
										<div class='mynone' style="display: block;">
											<div style="margin: 5rem 0 0rem;text-align: center;" v-if="windowWidth<900">
												<img @dragstart.prevent :src="require('@/image/onhand/mydown.png')" alt="" 
												@click='text4_line=!text4_line' :style='{transform:text4_line?"":"rotate(180deg)"}'
												style='width: 4rem;cursor: pointer;'>
											</div>
										</div>
									</div>
								</div>
							</a-col>
							</a-col>
					</a-row>
				</a-col>
				<br  class='mynone' v-if="model !='ipad'">
				<a-col :xl='{span:12}' :lg='{span:24}' v-for='(item,inds) in listdata.firstpage_imagearray2' :key='inds+693'  class='mynone'>
					<!-- <div data-aos="fade-up"> -->
					<!-- 图片+文字 -->
					<div class='heig_div' style="text-align: center;width: 85rem;padding-left: 3rem;" :style="{width:model=='ipad'?'40rem':'85rem',paddingBottom :model=='ipad'&&inds == (listdata.firstpage_imagearray2.length-1)?'3.5rem':''}">
						<img @dragstart.prevent :src="item.picurl" alt="" class='warp_abc'>
						<!-- style="max-width: 130%;margin-left: -10%;transform:translate3d(0px,-10px,0px)"> -->
					</div>
					<div class='heig_div'>
						<div>
							<div class='warp_headtext warp_headtext_ipad'>{{item.title}}</div>
							<div class='warp_meta warp_meta_ipad'>{{item.text}}</div>
						</div>
					</div>
				</a-col>
				<a-col :xl='{span:12}' :lg='{span:24}' v-for='(item,inds) in listdata.firstpage_imagearray2' :key='inds+6931' class='myun_none'>
					<!-- <div data-aos="fade-up"> -->
					<!-- 图片+文字 -->
					<a-row v-if='item.text&&item.picurl&&item.textcenter=="right"'>
						<a-col :span='12'>
							<div @dragstart.prevent class='heig_div '>
								<img @dragstart.prevent :src="item.picurl" alt="" class='warp_abc'>
								<!-- style="max-width: 130%;margin-left: -10%;transform:translate3d(0px,-10px,0px)"> -->
							</div>
						</a-col>
						<a-col :span='12'>
							<div class='heig_div' :class='inds > 3?"line_font":""'>
								<div>
									<div class='warp_headtext'>
										{{item.title}}
									</div>
									<div class='warp_meta'>
										{{item.text}}
									</div>
								</div>
							</div>
						</a-col>
					</a-row>
					<!-- 文字+图片 -->
					<a-row v-if='item.text&&item.picurl&&item.textcenter=="left"'>
						<a-col :span='12'>
							<div class='heig_div' :class='inds == 2?"line_font2":""'>
								<div>
									<div class='warp_headtext'>
										{{item.title}}
									</div>
									<div class='warp_meta'>
										{{item.text}}
									</div>
								</div>
							</div>
						</a-col>
						<a-col :span='12'>
							<div class='heig_div'>
								<img @dragstart.prevent :src="item.picurl" alt="" class='warp_abc'>
								<!-- style="max-width: 130%;margin-left: -10%;transform:translate3d(0px,-10px,0px)"> -->
							</div>
						</a-col>
					</a-row>
				</a-col>
			</a-row>
			<!-- </div> -->
			<div id='mybottom'></div>
			<div data-aos="fade-up">
				<div style="background-color: #fff;">
					<div class='headtitle'>
						{{listdata.firstpage_title1}}
					</div>
					<div class='fagenlist'>
						<div v-for='(item,ind) in listdata.firstpage_imagearray1' :key='ind+"222"' class='genlist'
						 :class='ind==1?"my_name1":""' @click='handopen(item)'>
							<img @dragstart.prevent :src="item.picurl" alt="">
							<div class='myname'>
							{{item.title}}</div>
							<div class='myfont'>{{item.text}}</div>
						</div>
					</div>
				</div>
			</div>
			
			<div data-aos="fade-up">
				<div>
					<div class='headtitle' style="background-color: transparent;">
						{{listdata.firstpage_title2}}
					</div>
					<div style="position: relative;" :style="{height:model == 'ipad'?'25rem':''}">
						<img :src="require('@/image/ybg.png')" alt="" style="width: 100%;"  class='myun_none'>
						<img :src="require('@/image/bg.png')" alt="" style="width: 100%;" :style="{height:model == 'ipad'?'25rem':''}" class='mynone'>
						<div class='isdivcen'>
							{{listdata.firstpage_text1}}
						</div>
					</div>
				</div>
			</div>
			<!-- 作品案例-->
			<div id="zpal_id"></div>
			<a-row id='zpal' class='mynone'>
				<div data-aos="fade-up">
					<div class='headtitle' style="margin-top: -0.2rem;width: 100vw;">{{listdata.firstpage_title3}}</div>
					<div class='my_lu_bo'>
						<div>
							<div v-for='(key,indv) in listdata.firstpage_imagearray3' :key='indv+690' class='myfa myfa_bor'>
								<img :src="key.picurl" alt="" >
								<div class='iscenter'>
									<div style="text-align: center;">
										<!-- <h3>{{key.title}}</h3> -->
										<span>{{key.text}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</a-row>
			<a-row id='zpal' class='myun_none'>
				<div data-aos="fade-up">
					<div class='headtitle' style="margin-top: -0.2rem;">{{listdata.firstpage_title3}}</div>
					<a-col :span='12' >
						<div class='myfa myfa_fa' v-if="listdata.firstpage_imagearray3">
							<img :src="listdata.firstpage_imagearray3[0].picurl" alt="" >
							<div class='iscenter'>
								<div style="text-align: center;">
									<span>{{listdata.firstpage_imagearray3[0].text}}</span>
								</div>
							</div>
						</div>
					</a-col>
					<a-col :span='12'>
						<a-row>
							<a-col :span='12' v-for='(key,indv) in listdata.firstpage_imagearray3' :key='indv+690' v-if='indv'>
								<div v-if='indv>0' class='myfa myfa_bor'>
									<img :src="key.picurl" alt="" >
									<div class='iscenter'>
										<div style="text-align: center;">
											<span>{{key.text}}</span>
										</div>
									</div>
								</div>
							</a-col>
						</a-row>
					</a-col>
				</div>
			</a-row>
			<!-- 域名搜索 -->
			<!-- <div data-aos="fade-up">
				<div class='headtitle'>
					域名搜索
				</div>
				<div>
					<img src="@/image/onhand/bg.png" alt="">
					<div class="warp_com">
						<a-row style="margin-bottom: 1.5rem;">
							<a-col :span='20'><input type="text" v-model='valput' class='warp_input'
									placeholder='找到你的域名'></a-col>
							<a-col :span='4'>
								<div style="text-align: right;"><button class='warp_btn1' @click='handso'>搜索</button>
								</div>
							</a-col>
						</a-row>
						<a-row type="flex" class="row-bg" justify="space-between">
							<a-col :span="2.5" v-for='(item,a) in listdata.imglist' :key='a+691'>
								<img :src="item" alt="" style="width: 100%;">
							</a-col>
						</a-row>
					</div>
				</div>
			</div> -->
		
			<div data-aos="fade-up">
				<div>
					<div class='headtitle'>
						{{listdata.firstpage_title4}}
					</div>
					<div class='warp_logo'>
						<img @dragstart.prevent v-for='(item,ind) in listdata.firstpage_imagearray4' :src="item" alt="">
					</div>
				</div>
			</div>
			<div class="jyjh_id"></div>
			<div data-aos="fade-up">
				<div class='headtitle '  style="background-color: transparent;">
					<!--  style="margin-top: -5rem;" -->
					{{listdata.firstpage_title5}}
				</div>
				<div class='my_email'>
					欢迎电邮 <a class='email_link' href="mailto:enquiry@qmisit.com">info@qmisit.com.hk</a>
				</div>
				<div class='fa_mysss'>
					<a-row style="margin-bottom: 20px" type='flex' justify='space-between'>
						<!-- <a-col :span='11'> -->
						<input type="text" v-model='params.name' class='warp_input mysss' placeholder='姓名'>
						<input type="text" v-model='params.companyname' class='warp_input mysss' placeholder='公司名称'>
					</a-row>
					<a-row style="margin-bottom: 20px" type='flex' justify='space-between'>
						<!-- <a-col :span='11'> -->
						<input  v-model='params.emailaddress' type="text" class='warp_input mysss' placeholder='电邮地址' @blur="emailfn">
						<!-- </a-col><a-col :span='11' :offset='2'> -->
						<input  v-model='params.phonenum' type="text" class='warp_input mysss' placeholder='联络电话' @input='numfn'>
						<!-- </a-col> -->
					</a-row>
					<a-row style="margin-bottom: 20px">
						<a-col :span='24' style='position: relative;'>
							<select name="cars" v-model='params.type' class='warp_input' placeholder='查询类型'>
								<option value="查询类型 (必需)">查询类型 (必需)</option>
								<option value="网页设计">网页设计</option>
								<option value="网上宣传">网上宣传</option>
								<option value="影片制作">影片制作</option>
								<option value="手机程式">手机程式</option>
								<option value="网上商店">网上商店</option>
								<option value="其他">其他</option>
							</select>
							
							<img :src="require('@/image/onhand/select_model.png')" alt="" class='my_down mynone'>
							<img :src="require('@/image/onhand/select.png')" alt="" class='my_down myun_none'>
							<!-- <a-select default-value="lucy" style='width: 100%;'>
							      <a-select-option value="jack">
							        Jack
							      </a-select-option>
								</a-select> -->
						</a-col>
					</a-row>
					<a-row style="margin-bottom: 20px">
						<a-col :span='24'>
							<textarea name="" v-model='params.content' id="" cols="30" rows="10" class='warp_input mytextarea' placeholder='内容'></textarea>
						</a-col>
					</a-row>
					<a-row style="margin-bottom: 20px">
					<a-col :span='24' style='position: relative;'>
						<select name="find-us" v-model='params.tojoinus' class='warp_input' aria-invalid="false">
							<option value="如何找到我们 ?">如何找到我们 ?</option>
							<option value="Yahoo">Yahoo</option>
							<option value="Google">Google</option>
							<!-- <option value="Facebook">Facebook</option> -->
							<!-- <option value="baidu">baidu</option> -->
							<option value="电邮">电邮</option>
							<option value="推特">推特</option>
							<option value="其他">其他</option>
						</select>
						<img :src="require('@/image/onhand/select_model.png')" alt="" class='my_down mynone'>
						<img :src="require('@/image/onhand/select.png')" alt="" class='my_down myun_none'>
					</a-col>
					</a-row>
					<div style="text-align: center;">
						<button class='warp_btn2' @click='handbtn'>提 交</button>
					</div>
					<strong></strong>
				</div>
			</div>
			<div style="background-color: #0d626b;">
				<div class='home_bottoom'>
					<div style="font-size: 13px;color: #fff;">
						{{listdata.firstpage_copyright}}
						<!-- © Copyright 2022 Dalian QMIS Software Technology Development Co., Ltd. All Rights Reserved -->
					</div>
					<div style="font-size: 13px;color: #fff;">
							<!-- 辽ICP备2021010794号-1 -->
							{{listdata.firstpage_recordnumber}} 
					</div>
				</div>
			</div>
		</div>
		<!-- <a-back-top id='my-back-top-demo-custom' /> -->
		<a-back-top id='my-back-top-demo-custom'>
			<div class="ant-back-top-inner">
				<img :src="require('@/image/onhand/up.png')" alt="">
			</div>
		</a-back-top>
		<handitem v-if='island' @child='handback'></handitem>
		<handopen v-if='openitem' :item='openitem' @child='handback'></handopen>
		<div class="menu_div" v-if="menuFlag" @click="Move()">
			<div style="text-align: center;color: #ffffff;" class="menu_class">
				<div class="back_hover" style="cursor: pointer;" :style="{color:colorFlag == 0 ? '#40bfbf':''}" @click="skipClick(0)">主页</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;"  @click="skipClick(1)">关于我们</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" :style="{color:colorFlag == 3 ? '#40bfbf':''}"  @click="skipClick(2)">服务范畴</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" :style="{color:colorFlag == 4 ? '#40bfbf':''}"  @click="skipClick(3)">作品案例</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;"  @click="skipClick(4)">就业机会</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" :style="{color:colorFlag == 6 ? '#40bfbf':''}"  @click="skipClick(5)">联络我们</div>
			</div>
		</div>
		<cookie></cookie>
	</div>
</template>

<script>
import cookie from '../../cookies/cookie.vue'
import AOS from "aos";
	import handitem from './handitem.vue'
	import handopen from './handopen.vue'
	// import "../node_modules/aos/dist/aos.css";

	export default {
		name: 'homepage',
		components: {
			handitem,handopen,cookie
		},
		data() {
			return {
				colorFlag:'0',
				onlinestate:sessionStorage.onlinestate,
				timeInterval: 0, //切换图片下标
				model:'',
				windowWidth: document.documentElement.clientWidth,
				menuFlag:false,
				openitem:'',
				text4_line:true,
				imglist:[
					require('@/image/hz1.png'),
					require('@/image/hz2.png'),
					require('@/image/hz3.png'),
					require('@/image/hz4.png'),
					require('@/image/hz5.png'),
					require('@/image/hz6.png'),
				],
				island: false,
				params: {
					name: '',
					companyname: '',
					emailaddress: '',
					phonenum: '',
					type: '查询类型 (必需)',
					"content":"",
					tojoinus: '如何找到我们 ?',
				},
				valput: '',
				isscrollnum: '',
				listdata: {
					// k:[
					// 	{
					// 		url :require('@/image/fw1.png'),
					// 		name:'企业与客户的双赢',
					// 		v:'“赢利”是铧亚科技与客户达到共赢的美好愿景。在客户的信赖与合作伙伴的支持下不断地成长，将是铧亚科技不断前行的永恒动力。'
					// 	},
					// 	{
					// 		url :require('@/image/fw2.png'),
					// 		name:'价值的意义 股东的信任',
					// 		v:'“价值”是铧亚科技对合作伙伴的责任，更是铧亚科技的最大动力。铧亚科技一如既往，继续努力，不断优化铧亚科技品牌的持续开发和创新升级，最大限度地为客户服务！'
					// 	},
					// 	{
					// 		url :require('@/image/fw3.png'),
					// 		name:'感恩社会 回报社会',
					// 		v:'“奉献”是铧亚科技始终坚持的社会使命，更是铧亚科技的企业魂。铧亚科技自创立起，就一直热心关注社会公益事业，铧亚科技被授予"希望工程特殊贡献奖"。'
					// 	},
					// ],
					logourl: require('@/image/onhand/banner.png'), //img/banner.png
					a4: [{
							text: '铧亚科技有限公司成立于2008年，由专注于金融与科技创新的专业人士成立。铧亚科技为客户提供完整的互联网解决方案，包括多媒体，创意网页设计、网络推广、手机APP程式开发等互联网软件开发服务为一体的科技公司。作为华亚金融集团的成员公司，不仅很好的执行我们的互联网开发服务，我们还享受华亚金融集团其他成员公司带来的协同效应，在亚洲过百名员工，建立不同单位和部门，全力支援我们的客户。虽然我们专注于我们的科技开发领域，我们不时接受多元，紧密的企业培训，以促进部门之间的知识与沟通，从而齐心协力为我们的客户提供满意的服务。',
							headtext: '关于铧亚科技',
							imgurl: '',
							textcenter: '',
							id: '',
						},
						{
							text: '随着信息时代的到来，信息的大量涌入让企业在信息处理方面应接不暇。传统的办公模式、对信息的处理方法已不能满足企业对信息快速、准确的处理要求。铧亚科技将传统的办公模式转向自动化办公管理模式，从而提高企业信息处理能力，增强企业的市场竞争力。',
							headtext: '企业管理系统',
							imgurl: require('@/image/onhand/img1.png'),
							textcenter: 'right',
							id: '',
						},
						{
							text: '当下，移动端产品横空出世，锐不可挡，足以表现出移动端网站的趋势。铧亚科技以智能适配多种终端设备为基准，专注于用户体验，透析移动互联网，缔造极致体验。',
							headtext: '移动应用程式开发',
							imgurl: require('@/image/onhand/img2.png'),
							// imgurl: require('@/image/onhand/download.jpg'),
							textcenter: 'right',
							id: '',
						},
						{
							text: '铧亚科技的创意主题，注重专业、高端、时效，为用户提供贴合的互动效果。我们拥有多年网站开发设计的高端人才，为您制作个性化站点,全方位展现您的企业优势。',
							headtext: '互联网开发',
							imgurl: require('@/image/onhand/img3.png'),
							textcenter: 'left',
							id: '',
						},
						{
							text: '打造品牌营销的创意文化公司，以打造行业新品牌为己任，提供从品牌文化塑造、品牌形象设计、环境艺术设计、影音产品定制、自媒体定制到配套产品采购、品牌营销活动的一站式服务。',
							headtext: '品牌形象设计',
							imgurl: require('@/image/onhand/img4.png'),
							textcenter: 'left',
							id: '',
						},
						{
							text: '电子商务利用计算机技术、网络技术和远程通信技术，实现整个商务买卖过程中的电子化、数字化和网络化。铧亚科技致力于用户体验，打造专业的B2B、B2C电商平台。',
							headtext: '电子商务',
							imgurl: require('@/image/onhand/img5.png'),
							textcenter: 'right',
							id: '',
						},
					],
					// a5: [{
					// 		url: require('@/image/onhand/anli1.png'),
					// 		title: '电子商务，移动应用程式开发，网站设计',
					// 		// name: '网站设计，互联网开发'
					// 	},
					// 	{
					// 		url: require('@/image/onhand/anli2.png'),
					// 		title: '网站设计',
					// 		// name: '网站设计'
					// 	},
					// 	{
					// 		url: require('@/image/onhand/anli3.png'),
					// 		title: '企业管理系统',
					// 		// name: '企业管理系统'
					// 	},
					// 	{
					// 		url: require('@/image/onhand/anli4.png'),
					// 		title: '移动应用程式开发',
					// 		// name: '移动应用程式开发'
					// 	},
					// 	{
					// 		url: require('@/image/onhand/anli5.png'),
					// 		title: '影片制作',
					// 		// name: '影片制作'
					// 	}
					// ],
					a6: '作品案例',
					imglist: [require('@/image/onhand/ym1.png'), require('@/image/onhand/ym2.png'), require(
						'@/image/onhand/ym3.png'), require('@/image/onhand/ym4.png'), require(
						'@/image/onhand/ym5.png'), require('@/image/onhand/ym6.png'), require(
						'@/image/onhand/ym7.png'), require('@/image/onhand/ym8.png')]
				},
				isscroll: 0,
				formtable: [{
						htmltext: "",
						name: "SHOUYE",
					},
					{
						htmltext: "",
						name: "SHOUYEFOOT",
					}
				],
				display:false,
			}
		},
		updated() {
			let imgList = document.getElementsByTagName('img');//图片集合
			let imgCount = imgList.length;//图片总数
			let imgLoad = 0;//加载完成的图片数量
			// console.log(imgCount,'图片总数');

			for (let i = 0; i < imgCount; i++) {
				
				imgList[i].onload = () => {
				imgLoad++;
				// console.log(imgLoad);
				if (imgLoad === imgCount) {
					this.display = true;
					console.log("图片加载完成 展示组件");
				}
				}
			}

			setTimeout(() => {
				if (!this.display) {
				this.display = !this.display;
				console.log("超时 强制显示");
				}
			}, 5000)

		},
		mounted() {
			this.colorFlag = this.$route.query.id
			this.timerMethod();
		
			
			let sta = ''
			let phone = ''
			function browserRedirect() {
				var sUserAgent = navigator.userAgent.toLowerCase();
				var bIsIpad = sUserAgent.match(/ipad/i) == "ipad";
				var bIsIphoneOs = sUserAgent.match(/iphone os/i) == "iphone os";
				var bIsMidp = sUserAgent.match(/midp/i) == "midp";
				var bIsUc7 = sUserAgent.match(/rv:1.2.3.4/i) == "rv:1.2.3.4";
				var bIsUc = sUserAgent.match(/ucweb/i) == "ucweb";
				var bIsAndroid = sUserAgent.match(/android/i) == "android";
				var bIsCE = sUserAgent.match(/windows ce/i) == "windows ce";
				var bIsWM = sUserAgent.match(/windows mobile/i) == "windows mobile";
				var bmacintosh = sUserAgent.match(/macintosh/i) == "macintosh";
				// 您的浏览设备为：
				if(document.body.clientWidth <= 1100&&bmacintosh){
					sta = 'ipad'
					phone = 'ipad'
				}else
				if(bIsIpad){
					// ||bmacintosh
					sta = 'ipad'
					phone = 'ipad'
					// sta = 'phone'
				}else
				if(bIsIphoneOs || bIsAndroid){
					sta = 'phone'
				if (bIsIphoneOs) {
					phone = 'iphone'
				}else{
					phone = 'android'
				}
				}else{
					sta = 'pc'
				phone = 'pc'
				}
			}
			browserRedirect();
			this.model = sta;
			console.log(sta,phone,"机型。。。");
			this.getdate()
			// this.getdata()
			AOS.init();
			// 你也可以在这里设置全局配置
			AOS.init({
				offset: 180,
				duration: 400, //持续时间
				easing: 'ease-in-sine',
				delay: 300,
				// once:true,
			})
			var scrolltop = '';
			// let mybottomdom = '';
			// setTimeout(()=>{
			// 	 mybottomdom = document.getElementById('mybottom').offsetTop
			// },500)
			window.onscroll = function(e) {
				this.isscrollnum = JSON.parse(JSON.stringify(e))
				let island = 1.5
				//判断scroll是否向上还是向下 比较当前的值是否比之前值的大小
				if (scrolltop > this.isscroll) { //向下
					island = 1.5
					// if (scrolltop < 857) {
					document.getElementById('wrap_hand').className = 'wrap_hand'
					// }
				} else { //上
					island = -1.5
					// if (scrolltop < 50) {
					// 	document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animations'
					// } else {
					document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animation'
					// }
				}
				if (scrolltop < 50) {
					// document.getElementById('mytitle_img').className = 'mytitle_img'
				} else if (scrolltop > 857) {
					// document.getElementById('mytitle_img').className = ' ';
				}
				let imgList = document.getElementsByClassName("warp_abc");
				let iimg_fa = document.getElementsByClassName('heig_div');
				// let isbili = (document.documentElement.scrollTop/document.documentElement.scrollHeight).toFixed(2);
				this.isscroll = scrolltop;
				scrolltop = document.documentElement.scrollTop;
				// for (let key in imgList) {
				// 	if (imgList[key].style) {
				// 		let start = imgList[key].style.transform.split(',')[1] || '0px';
				// 		start = start.split('px')[0] * 1;
				// 		let strheight = (imgList[key].clientHeight < (iimg_fa[key].clientHeight - (start - island))) ?
				// 			start : start - island;
				// 		strheight = isNaN(strheight) ? 0 : strheight > 0 ? 0 : strheight;
				// 		let str3d = 'translate3d(0px,' + strheight + 'px, 0px)';
				// 		if (key == 0 && (iimg_fa[key].clientHeight + document.getElementById('mytitle_img')
				// 				.clientHeight > scrolltop)) {
				// 			imgList[key].style.transform = str3d;
				// 		}
				// 		if ((key == 1 || key == 2) && (iimg_fa[key].clientHeight * 2 + document.getElementById(
				// 				'mytitle_img').clientHeight > scrolltop) &&
				// 			(iimg_fa[key].clientHeight < scrolltop)) {
				// 			imgList[key].style.transform = str3d;
				// 		}
				// 		if ((key == 4 || key == 3) && (iimg_fa[key].clientHeight * 3 + document.getElementById(
				// 				'mytitle_img').clientHeight > scrolltop) &&
				// 			document.getElementById('mytitle_img').clientHeight < scrolltop) {
				// 			imgList[key].style.transform = str3d;
				// 		}
				// 	}
				// }
				
			}
			setTimeout(()=>{
				if (this.colorFlag == '3') {
					this.skipClick(2)
				} else if (this.colorFlag == '4') {
					this.skipClick(3)
				}else if (this.colorFlag == '6') {
					this.skipClick(5)
				}else{
					window.scrollTo({ top: 0, behavior: 'smooth' })
				}
			},500)
			// const element = document.getElementById('aaabbb');
			// const height = element.clientHeight ;
			// console.log(height);

			// 服务范畴手机
			// window.scrollTo({ top: 500, behavior: 'smooth' })
			// 服务范畴手机
			// window.scrollTo({ top: 500, behavior: 'smooth' })

		},
		methods: {
			backClick(){
				this.$router.push({ path: '/', query: { id: 0 }  });
				setTimeout(() => {
                    window.location.reload();
                }, 500);
			},
			timerMethod() {
			  setInterval(() => {
				  // 切换图片
				  // if (this.timeInterval < this.imgArr.length-1) {
					if (sessionStorage.onlinestate=='pc') {
						if (this.timeInterval < this.listdata.firstpage_banner_pic.length-1) {
							this.timeInterval+=1;
						}else{
							this.timeInterval = 0
							// return
						}
					}else{
						if (this.timeInterval < this.listdata.firstpage_banner_pic_app.length-1) {
							this.timeInterval+=1;
						}else{
							this.timeInterval = 0
							// return
						}
					}
			  }, 10000)
			},
			skipClick(val){
				if (val == 0) {
                    this.$router.push({ 
						path: '/', 
						query: { id: 0 }  
					});
					window.scrollTo({ top: 0, behavior: 'smooth' })
					
                }
				if (val == 1) {
                    this.$router.push({ 
						path: '/aboutUs', 
						query: { id: 2 } 
					});
                }
				if (val == 2) {
                    this.$router.push({ 
						path: '/', 
						query: { id: 3 } 
					});
					console.log(window.innerWidth);
					if (this.onlinestate == 'pc') {
						if (window.innerWidth>1920) {
							// 服务范畴高分辨率pc
							window.scrollTo({ top: 2000, behavior: 'smooth' })
						}else{
							// 服务范畴正常pc
							window.scrollTo({ top: 1400, behavior: 'smooth' })
							
						}
					}
					if (this.onlinestate == 'ipad') {
						window.scrollTo({ top: 1000, behavior: 'smooth' })
					}
					if (this.onlinestate == 'phone') {
						window.scrollTo({ top: 700, behavior: 'smooth' })
					}
                }
				if (val == 3) {
					 this.$router.push({ 
						path: '/', 
						query: { id: 4 } 
					});
					if (this.onlinestate == 'pc') {
						if (window.innerWidth>1920) {
							// 服务范畴高分辨率pc
							window.scrollTo({ top: 4200, behavior: 'smooth' })
						}else{
							// 服务范畴正常pc
							window.scrollTo({ top: 3100, behavior: 'smooth' })
							
						}
					}
					if (this.onlinestate == 'ipad') {
						window.scrollTo({ top: 2500, behavior: 'smooth' })
					}
					if (this.onlinestate == 'phone') {
						window.scrollTo({ top: 1900, behavior: 'smooth' })
					}
                }
                if (val == 4) {
                    this.$router.push({ 
						path: '/aboutUs', 
						query: { id: 5 } 
					});
                }
                if (val == 5) {
                    this.$router.push({
						// path: '/contactUs', 
						path: '/', 
						query: { id: 6 } 
					});
					// 获取页面最底部元素
					const footerElement = document.querySelector('.jyjh_id');
					// 滚动到底部的位置
					const scrollOptions = {
						top: footerElement.offsetTop,
						behavior: 'smooth' // 使用平滑滚动效果
					};
					// 执行滚动
					window.scrollTo(scrollOptions);
					
                }
              
				this.colorFlag = this.$route.query.id
            },
			
			menuPage(){
				console.log("菜單111111");
				this.menuFlag = !this.menuFlag
				if(this.menuFlag){
					this.stopMove()
				}else{
					this.Move()
				}
			},
			stopMove(){
				// let m = function(e){e.preventDefault();};
				// document.body.style.overflow='hidden';
				// document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
			},
			//开启页面滚动
			Move(){
				this.menuFlag = false
				let m =function(e){e.preventDefault();};
				document.body.style.overflow='';//出现滚动条
				document.removeEventListener("touchmove",m,{ passive:true });
			},
			handopen(val){
				if(sessionStorage.onlinestate == 'phone'){
					this.openitem = val
				}
			},
			getdate(){
				console.log(sessionStorage.onlinestate)
				this.$ajax({
					method:'post',
					url:this.$api.getFirstPage,
					data:{
						appflag:sessionStorage.onlinestate=='phone'?'1':0
					}
				}).then(res=>{
					if(res.data.qmiscode === 1000){
						this.listdata = res.data
						// if(res.data.is_use_pic == '0'){
						// 	this.listdata.firstpage_banner_pic = [
						// 		'https://hk.qmisit.com/imgbank/dw_it_pc.png',
						// 		'https://hk.qmisit.com/imgbank/banner_it_pc.png',
						// 	]
						// 	this.listdata.firstpage_banner_pic_app = [
						// 		'https://hk.qmisit.com/imgbank/dw_it_phone.png',
						// 		'https://hk.qmisit.com/imgbank/banner_it_phone.png',
						// 	]
						// }
					}else{
						this.$message.warning(res.data.qmismsg)
					}
				})
			},
			numfn() {
				this.params.phonenum = isNaN(this.params.phonenum) ? "" : this.params.phonenum
			},
			handback() {
				this.island = false
				this.openitem = ''
			},
			emailfn() {
				if (this.params.emailaddress) {
					var t = /^[A-Za-zd0-9]+([-_.][A-Za-zd]+)*@([A-Za-zd]+[-.])+[A-Za-zd]{2,5}$/;
					if (!(t.test(this.params.emailaddress))) {
						this.params.emailaddress = ''
						this.$message.warning('請輸入正確的电邮地址')
					}
				}
			},
			handbtn() {
				let inputList = document.getElementsByClassName("warp_input");
				let ind = 0;//1
				let istrue = 0
				let strobj = JSON.parse(JSON.stringify(this.params))
				for (let key in this.params) {
					if(this.params[key]=='查询类型 (必需)'){
						inputList[ind].style.border = '1px solid red'
					}else if(this.params[key]=='如何找到我们 ?'){
						strobj[key] = ''
						istrue++
					}else if (this.params[key]) {
						inputList[ind].style.border = ''
						istrue++
					} else {
						inputList[ind].style.border = '1px solid red'
					}
					ind++
				}
				if (istrue == 7) {//6
					this.$ajax({
						method: 'post',
						url: this.$api.sendLeaveMsgEmail,
						data:strobj
					}).then(res => {
						if (res.data.qmiscode == 1000) {
							this.island = true
						}
					})
				}
			},
			handtogo() {
				let iimg_fa = document.getElementById('mytitle_img').clientHeight;
				document.documentElement.scrollTop = iimg_fa;
			},
			handso() {
				if (this.valput) {
					this.$router.push({
						path: '/sreachpage',
						query:{userId:this.valput}
					});
					return
					let strarr = this.valput.split('.')
					if (strarr.length > 1) {
						window.open('https://wanwang.aliyun.com/domain/searchresult/#/?keyword=' +
							strarr[0] + '&suffix=.' + strarr[1] + '.cn')
					} else {
						window.open('https://wanwang.aliyun.com/domain/searchresult/#/?keyword=' +
							strarr[0] + '&suffix=.com.cn')
					}
				}
			},
			getdata() {
				this.$ajax({
					method: 'get',
					url: this.$api.gethtml,
				}).then(res => {
					if (res.data.result) {
						this.formtable = res.data.result
						
					}
				})
			}
		}
	}
</script>
<style lang='less' scoped>

	* {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		font-family: arial;
	}
	body {
		padding: 0;
		margin: 0;
		text-align: center;
		font-family: arial;
	}
	section {
		// height: 300px;
		// padding-top: 100px;
		float: left;
		width: 50%;
		position: relative;
		/*==============++++================*/
	}
	.menu {
		height: 28px;
		width: 28px;
		position: relative;
		margin: auto;
		// padding-top: 20px;
		border: 5px solid transparent;
		-moz-border-radius: 100%;
		-webkit-border-radius: 100%;
		border-radius: 100%;
		-moz-transition: 0.3s;
		-o-transition: 0.3s;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		cursor: pointer;
	}
	.bar {
		height: 3px;
		width: 28px;
		display: block;
		// margin: 10px auto;
		position: relative;
		background-color: #000;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		-moz-transition: 0.4s;
		-o-transition: 0.4s;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
	.model-1 .bar {
		position: absolute;
	}
	.model-1 .bar:nth-of-type(1) {
		// top: 15px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(2) {
		top: 10px;
		-moz-transition: 0.3s ease 0.3s;
		-o-transition: 0.3s ease 0.3s;
		-webkit-transition: 0.3s ease;
		-webkit-transition-delay: 0.3s;
		transition: 0.3s ease 0.3s;
		-moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(3) {
		top: 20px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .menu_hover .bar:nth-of-type(1) {
		top: 10px;
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.model-1 .menu_hover .bar:nth-of-type(2) {
		opacity: 0;
	}
	.model-1 .menu_hover .bar:nth-of-type(3) {
		top: 10px;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.menu_class{
		font-size: 26px;
	}
	.back_hover:hover{
        color: #40bfbf;
    }
	.menu_div{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}


	.my_email{
		text-align: center;margin-bottom: 3rem;
	}
	.title_font{
		font-size: 48px;text-align: center;color: #fff;font-weight: 700;
	}
	.myfont{
		text-align: left;color: #000000;
	}
	.mynone{
		display: none;
	}
	.myun_none{
		display: inline-block;
	}
	.warp_logo{
		display: flex;justify-content: space-around;background: #fff;padding-bottom: 5rem;
	}
	
	.myname{
		font-weight: 600;margin: 1.5rem 0 .5rem;font-size: 20px;color: #000000;
	}
/* 	#zpal{
		background-color: #00FF00;
	}  */
	.myfa_fa{
		width: 100%;height: 33.4rem;position: relative;
	}
	.myfa_fa img{
		width: 100%;height: 33.4rem;
	}
	.myfa_bor{
		width: 100%;height: 16.7rem;position: relative;
	}
	.myfa_bor img{
		width: 100%;height: 16.7rem;
	}
	body{
		color: #000;
	}
	.home_bottoom{
		width: 90%;margin: 5px auto;
		display: flex;align-items: center;
		justify-content: space-between;
		height: 45px;
		padding: 10px 0;
	}
	.isdivcen{
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 0 290px;
		text-align: center;
		color: #fff;
	}
	
	.mydown>img {
		width: 1rem;
		cursor: pointer;
	}

	.mydown {
		position: absolute;
		bottom: -8rem;
		left: 49.5%;
	}

	.mydown img:hover {
		margin-top: .5rem;
		transition: .3s ease-in-out;
	}

	#my-back-top-demo-custom {
		box-sizing: border-box;
		margin: 0;
		padding: 0;
		color: rgba(0, 0, 0, 0.65);
		font-size: 16px;
		font-variant: tabular-nums;
		line-height: 1.5;
		list-style: none;
		font-feature-settings: 'tnum';
		position: fixed;
		right: 50px;
		bottom: 105px;
		/* line-height: 40px; */
		text-align: center;
		border-radius: 4px;
		/* background-color: rgba(0,0,0,.45);  */
		z-index: 10;
		/* 		width: 40px;
		height: 40px; */
		cursor: pointer;
	}

	.email_link {
		color: #000;
	}

	.email_link:hover {
		color: #126567;
	}

	.iscenter {
		span {
			font-size: 15px;
			text-align: center;
		}

		h3 {
			font-size: 17px;
			color: #fff;
			margin: 0;
			text-align: center;
		}
	}

	.iscenter {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: rgba(64, 191, 191, .6);
		opacity: 0;
	}

	.myfa:hover .iscenter {
		opacity: 1;
		z-index: 10;
		color: #fff;
		transition: transform 600ms var(--acc-back), opacity 100ms ease-in;
	}

	.heard {
		background-color: #f5f5f5;
	}

	.homepage {
		width: 100%;
		overflow: hidden;
	}

	.warp_img {
		img {
			width: 100%;
			/* width: 150%; */
			max-width: '';
		}
	}


	.headtitle {
		text-align: center;
		font-size: 27px;
		font-weight: 600;
		padding: 72px 0 36px;
		animation-name: anim_btt;
		background-color: #fff;
		color: #000000;
	}


	.heard_left {
		float: left;
		padding: 10px;
	}
	.heard_left img {
	width: 17rem;
	}

	.heard_right {
		float: right;
		padding-top: 1.3rem;
	}

	.handtitle {
		position: relative;
		width: 100vw;
		overflow: hidden;
		// border-top: 10px solid #010a3b;
		height: 101vh;
		.warp_lit {
			text-align: center;
			position: absolute;
			top: 40%;
			width: 100%;
		}
	}

	.warp_btn {
		cursor: pointer;
		outline: none;
		/* width: 7.5rem; */
		/* height: 2.5rem; */
		/* border-radius: 6px; */
		padding: 8px 32px;
		border-radius: 50px;
		transition: 0.5s;
		// background-color: #40bfbf;
		background: linear-gradient(45deg, #8097e4, #caa0e1);
		border: 0;
		font-size: 18px;
		color: #fff;
		margin-top: 70px;
	}
	.warp_btn:hover{
		color: #8097e4;
		background: #fff;
	}

	.warp_btn2 {
		border-radius: 50px;
		cursor: pointer;
		outline: none;
		/* width: 6rem; */
		/* height: 2.5rem; */
		background-color: #40bfbf;
		border: 0;
		font-size: 18px;
		color: #fff;
		padding: 8px 32px;
		margin: 2rem 0 2rem;
	}
	
	.warp_btn2:hover{
		color: #40bfbf;
		background-color: #fff;
	}
	.warp_btn1 {
		cursor: pointer;
		outline: none;
		width: 10.5rem;
		height: 2.5rem;
		background-color: #40bfbf;
		border: 0;
		font-size: 1rem;
		color: #fff;
		/* margin-top: 2.6rem; */
	}

	.warp_input {
		width: 100%;
		height: 40px;
		background-color: #fff;
		border: 0;
		/* font-size: 1rem; */
		/* appearance: auto; */
		padding: 0 10px;
		font-size: 14px;
		&:focus {
			border: 0;
			outline: none;
		}
	}

	.warp_input::-webkit-input-placeholder {
		/* WebKit browsers */
		/* color: #bababa;  */
		font-size: 14px;
	}
	.mytextarea{
		height: 7rem;padding: 8px 10px;
	}
	.lit_tap {
		font-size: 26px;
		color: #fff;
		font-weight: 600;   
		/* background-color: #0c8b97; */
		display: inline-block;
		padding: 0 5px;
		margin-top: 30px;
	}

	.warp_com {
		width: 90%;
		margin: 0 auto;
		position: relative;
		top: -13rem;
	}


	.heig_div {
		height: 25.8rem;
		width: 100%;
		overflow: hidden;
		background-color: #f5f5f5;
		color: #000;
		/* 	position: relative;
		overflow: hidden; */
	}

	.line_font {
		background-color: #40bfbf;
		color: #fff;
		height: 25.8rem;
	}

	.line_font2 {
		background-color: #f3a54b;// #63a2d2;
		color: #fff;
		height: 25.8rem;
	}

	.line_font>div,
	.line_font2>div {
		padding: 5rem;
	}

	.warp_headtext {
		font-size: 27px;
		margin: 2rem 0;
	}

	.warp_meta {
		font-size: 16px;
		line-height: 1.5rem;
	}

	.fa_mysss{
		width: 1128px;
		margin: 0 auto;
	}
	
	.mysss{
		width: 555px;
	}
	.heig_div>div {
		padding: 5rem 3rem;
	}

	/* 	.warp_img {
		width: 100%;
		height: 30rem;
		position: absolute;
		bottom: 0;
	} */

	.wrap_hand {
		/* display: inline-block; */
		overflow: hidden;
		position: absolute;
		width: 100%;
		/* left: 4%; */
		padding: 10px 4%;
		z-index: 100;
		background: rgba(255, 255, 255, 0.7);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}

	.wrap_hand_animations {
		position: fixed;
		top: 0;
	}

	.wrap_hand_animation {
		.heard_left {
			padding: 0;
		}

		.wrap_hand {
			padding: 8px 4%;
		}
	}
	
	.genlist{
		text-align: center;width: 25%;margin: 1rem 0 3rem;
	}
	.fagenlist{
		display: flex;justify-content: space-evenly;
	}
	
	.wrap_hand_animation {
		position: fixed;
		top: 0;
		-webkit-animation-name: hand-out;
		-moz-animation-name: hand-out;
		-o-animation-name: hand-out;
		animation-name: hand-out;
		-webkit-animation-duration: .5s;
		-moz-animation-duration: .5s;
		-o-animation-duration: .5s;
		animation-duration: .5s;
		background: rgba(255, 255, 255, .7);
	}

	.wrap_hand_animation img {
		-webkit-transition: padding 0.3s linear;
		-moz-transition: padding 0.3s linear;
		-o-transition: padding 0.3s linear;
		transition: padding 0.3s linear;
	}

	@keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-webkit-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-moz-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-o-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	.mytitle_img {
		-webkit-animation-name: zoom-out;
		-moz-animation-name: zoom-out;
		-o-animation-name: zoom-out;
		animation-name: zoom-out;
		-webkit-animation-duration: 10s;
		-moz-animation-duration: 10s;
		-o-animation-duration: 10s;
		animation-duration: 10s;
		width: 100%;
		// width: 100vw;height: 101vh;
	}

	@-webkit-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-moz-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-o-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}
	.my_down{
		    position: absolute;
		    top: .9rem;
		    right: 10px;
	}
	@media screen and(max-width:980px){
		.menu_class{
			font-size: 4.5rem;
		}
		.warp_btn{
			font-size: 16px;
		}
		#my-back-top-demo-custom {
			display: none;
		}
		.title_font{
			font-size: 20px;
		}
		.myfont{
			display: none;
		}
		.lit_tap{
			    font-size: 18px;
				font-weight: 500;
			    margin-top: 12px;
		}
		.heard_left img {
			width: 63rem;
		}
		.heard_right{
			padding-top: 3.3rem;
			padding-right: 3.3rem;
		}
		.warp_meta{
			/* line-height: 2.3; */
			padding: 0 ;
			/* font-size: 3.5rem; */
			line-height: 1.4;
			font-size: 2.8rem;
		}
		.heig_div{
			height: unset;
		}
		.line_font{
			height: unset;
		}
		.warp_headtext{
			text-align: center;
		}
		.genlist img{
			width: 15rem;
		}
		.genlist{
			margin: 1rem 0 5.5rem;
		}
		/* .fagenlist{
			display: inline-block;
			justify-content: center;
			text-align: center;
		} */
		.myname{
			font-weight: 500;margin: 3.5rem 13px 0;font-size: 16px;color: #000000;
		}
		.isdivcen{
			padding: 0 9%;
			font-size: 15px;
			text-align: left;
		}
		.mytitle_img {
			-webkit-animation-name: zoom-out;
			-moz-animation-name: zoom-out;
			-o-animation-name: zoom-out;
			animation-name: zoom-out;
			-webkit-animation-duration: 10s;
			-moz-animation-duration: 10s;
			-o-animation-duration: 10s;
			animation-duration: 10s;
			width: 100vw;
			height: auto;
			// height: 85rem;
		}
		// }
		.home_bottoom{
			width: 100%;
			/* margin: 5px auto; */
			display: inline-block;
			/* align-items: center; */
			/* justify-content: space-between; */
			height: unset;
			padding: 0
		}
		.home_bottoom div{
			padding: 5px 5%;
		}
		.mysss{
			width: 45%;
		}
		.fa_mysss{
			width: 90%;
			margin: 0 auto;
		}
		.my_down{
			position: absolute;
			top: 4.2rem;
			right: 10px;
			width: 14px;    
		}
		.warp_btn2{
			/* width: 100%; */
			margin-bottom: 40px;
		}
		.mytextarea{
			height: 40px;padding: 8px 10px;
		}
		.warp_headtext{
			/* width: 90%; */
			margin: 2rem 0 2rem;
			font-size: 4.4rem;
			text-align: left;
			font-weight: 600;
		}
		.heig_div > div{
		    padding: 1rem 0;
		    margin-right: 3rem;
			height: 44rem;
			/* word-break: break-all;
			text-overflow: ellipsis; */
			overflow: hidden;
			/* display: -webkit-box;
			-webkit-line-clamp: 10;
			-webkit-box-orient: vertical; */
			padding-left: 3rem;
			box-shadow: 3px -1px 5px rgba(0, 0 ,0 , 0.25);
			border-bottom: 1px solid rgb(219, 219, 219);
		}

		.heig_div > img{
			width: 45rem;
			height: 44rem;
		}
		.warp_headtext1{
			    border-bottom: .5px solid rgba(255,255,255,.5);
			    padding-bottom: 20px;
		}
		
		.my_name1{
			border-left: 1px solid #f1f1f1;
			border-right: 1px solid #f1f1f1;
			padding: 0 2rem;
			width: 34%;
		}
		
	
		.warp_logo{
			flex-wrap: wrap;
		}
		.warp_logo img{
			width: 27rem;
			height: 11rem;
			margin-top: 4rem;
		}
		.mynone{
			display: inline-block;
		}
		.mynone{
			display: flex;
		}
		.mynone .heig_div{
			padding: 3.5rem 0 .5rem;
		}
		
		.mynone .my_lu_bo{
			overflow-y: auto;
			width: 100vw;
			img{
				width: 100%;
			}
			.myfa{
				margin-left:4rem;
			}
		}
		.mynone .my_lu_bo>div{
			width: 330rem;
			display: flex;
		}
		
		.myfa{
			width: 62rem;height: unset;position: relative;
			float: left;
		}
		.myfa img{
			width: 40rem;height: unset;
		}
		.myfa_fa{
			width: 40rem;height: unset;position: relative;
		}
		
		.myfa_fa img{
			width: 40rem;height: unset;
		}
		.myun_none{
			display: none;
		}
		
		.headtitle{
			padding: 26px 0;
			font-size: 22px;
		}
		.warp_btn {
			padding: 8px 20px;
			margin-top: 20px;
		}
		.handtitle .warp_lit {
			top: 35%;
		}
		.handtitle{
			// height: 85rem;
			height: auto;
		}
		.mydown{
			display: none;
		}
		.my_top .warp_meta{
			font-size: 16px;
		}
		.text4{
			word-break: break-all;
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;	
		}
		.my_top .warp_headtext{
			text-align: center;
			margin: 1.5rem 0 5rem;
		}
		.my_email{
			font-size: 12px;
			position: relative;
			top: -3rem;
		}
		.heard_left{
			padding: 0;
		}

	}

	.ipad_class{
		.menu_class{
			font-size: 26px !important;
		}
		.heard_left img{
			width: 35rem;
		}
		.heard_right{
			padding-top: 2rem;
		}
		.warp_headtext{
			font-size: 20px;
		}
		.warp_meta{
			font-size: 16px;
		}
		.warp_headtext_ipad{
			font-size: 19px !important;
		}
		.warp_meta_ipad{
			font-size: 15px !important;
		}
		
		.headtitle{
			font-size: 20px;
		}
		.my_down{
			top: 2.2rem;
		}
		.mytitle_img{
			height: auto;
		}
		.handtitle{
			height: auto;
		}
		.wrap_hand{
			padding: 5px 4%;
		}
		.heig_div > img{
			height: 25rem;
		}
		.heig_div > div{
			height: 25rem;
			padding-right: 3rem;
		}
		.genlist img{
			width: 7rem;
		}
	
	}
	.aaa{
		  display: block !important;
	  }
	
</style>
