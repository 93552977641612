<template>
	<div>
		<a-modal id='homeopen' width='600px' v-model='visible' on-ok='handleok' :dialog-style='{top:"25vh"}'
			@cancel='handCancel' :footer='null' :keyboard='false' :maskClosable='false'>
			<div class='mark-file'></div>
			<div solt='title'></div>
			<div class='first_div'>
				<div class='firts_gre'>
					<div style='background-color: #fff;text-align: center;padding: 10px 0 0;'>
						<img :src="item.picurl" alt="" width="70px">
						<div class='myname'>
						{{item.title}}</div>
						<div class='myfont'>{{item.text}}</div>
						<!-- <div class='pas_btn' @click='handCancel()'>完 成</div> -->
					</div>
				</div>
			</div>
		</a-modal>
	</div>
</template>

<script>
	export default {
		props: ['item'],
		data() {
			return {
				visible: true,
			}
		},
		methods: {
			handCancel() {
				this.$emit('child', 0)
			}
		}
	}
</script>

<style lang='less'>
	#homeopen {
		.ant-modal-content{
			margin: 0 10%;
		}
		.myfont{
			text-align: left;color: #000000;
			line-height: 1.6;
			/* padding-bottom: 12px; */
			margin: 0 10px;
			font-size: 4rem;
			min-height: 40rem;

		}
		.myname{
			font-weight: 600;
			margin: 5rem 0 3rem;
			font-size: 4.5rem;
			color: #000000;
		}
		.ant-modal-close-x {
			display: block;
		}

		.divmax {
			font-size: 18px;
		}

		.firts_gre {
			width: 600px;
			border-radius: 4px;
			overflow: hidden;
			position: relative;
			/* margin-top: -22vh; */
		}

		.mark-file {
			position: absolute;
			top: 0;
			left: 0;
		}

		.pas_btn {
			background-color: #1697A7;
			color: #fff;
			width: 110px;
			height: 40px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 40px auto 20px;
			font-size: 18px;
			cursor: pointer;
		}
	}
	@media screen and (max-width:980px){
		#homeopen  .firts_gre {
			width: 100%;
		}
	}
</style>
